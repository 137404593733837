import images from "@/assets/images";
import routes from "./routes";

export const profileRankId = {
  BRONZE: 4,
  SILVER: 1,
  GOLD: 2,
  DIAMOND: 3,
};

export const profileRank = {
  BRONZE: "Hạng Đồng",
  SILVER: "Hạng Bạc",
  GOLD: "Hạng Vàng",
  DIAMOND: "Hạng Kim Cương",
};

export const rankImg = {
  [profileRank.BRONZE]: images.bronzeRank,
  [profileRank.SILVER]: images.silverRank,
  [profileRank.GOLD]: images.goldRank,
  [profileRank.DIAMOND]: images.diamondRank,
};

export const orderStatus = {
  WAITING_CONFIRM: "Chờ xác nhận",
  CONFIRMED: "Xác nhận",
  PROCESSING: "Đang xử lý",
  DELIVERING: "Đang giao hàng",
  DELAY_DELIVERING: "Delay giao hàng",
  COMPLETED: "Đã hoàn thành",
  REFUNDED: "Đã hoàn tiền",
  RETURNED: "Đã hoàn trả",
  CANCELED: "Huỷ",
  FINISHED: "Đã xong",
};

export const orderStatusList = {
  WAITING_ORDERS: [
    orderStatus.WAITING_CONFIRM,
    orderStatus.CONFIRMED,
    orderStatus.PROCESSING,
  ],
  DELIVERING_ORDERS: [orderStatus.DELIVERING, orderStatus.DELAY_DELIVERING],
  RECEIVED_ORDERS: [
    orderStatus.COMPLETED,
    orderStatus.REFUNDED,
    orderStatus.RETURNED,
  ],
  ORDERS_HISTORY: [],
};

export const pathNameOrderStatusList = {
  [routes.WAITING_ORDERS]: [
    orderStatus.WAITING_CONFIRM,
    orderStatus.CONFIRMED,
    orderStatus.PROCESSING,
  ],
  [routes.DELIVERING_ORDERS]: [
    orderStatus.DELIVERING,
    orderStatus.DELAY_DELIVERING,
  ],
  [routes.RECEIVED_ORDERS]: [
    orderStatus.COMPLETED,
    orderStatus.REFUNDED,
    orderStatus.RETURNED,
  ],
  "orders-history": [],
};
